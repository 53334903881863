import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#DFBB8B',
    },
    secondary: {
      main: '#083A39',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#083A39',
    },
  },
  overrides:{
    MuiCssBaseline:{
      '@global': {
        body: {
          fontFamily: 'Raleway, sans-serif',
        },
      },
    }
  }
});

export default theme;