import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from "@reach/router";
import {
    makeStyles, Button, Drawer
} from "@material-ui/core";
import { GOLD, GREEN } from "./App";
import { lighten } from "@material-ui/core/styles";
import MenuIcon from '@material-ui/icons/Menu';

const styles = makeStyles(theme => ({
    link: {
        fontSize: 18,
        fontWeight: "bold",
        textDecoration: "none",
        color: GOLD,
        "&:hover": {
            color: lighten(GOLD, 0.2),
        }
    },
    navBar: {
        background: "transparent",
        boxShadow: "none",
        display: "flex",
        alignItems: "center",
        marginTop: 40,
        marginBottom: 40,
        [theme.breakpoints.down("md")]: {
            alignItems: "flex-end",
            marginTop: 10,
            marginBottom: 10,
        }
    },
    toolbar: {
        justifyContent: "space-between",
        width: "100%",
        maxWidth: 1200,
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    drawer: {
        background: GREEN,
        padding: 20,
    },
    drawerLink: {
        marginBottom: 15,
    },
    hamburgerButton: {
        color: GOLD,
        fontSize: 40,
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "block",
        }
    },
}));

export const NavLink = (props:any) => {
    const classes = styles();
    return(
        <Link
        {...props}
        className={`${classes.link} ${props.className}`}
        getProps={({ isCurrent }) => {
            // the object returned here is passed to the
            // anchor element's props
            return {
                style: {
                    // fontWeight: isCurrent ? "bold" : "normal",
                    textShadow: isCurrent ? "1px 1px black" : "",
                    borderBottom: isCurrent ? `1px solid ${GOLD}` : "none",
                }
            };
        }}
        />
    )
};

export const Menu = (props: any) => {
    const classes = styles();
    const [ openDrawer, setOpenDrawer ] = React.useState(false);
    return (
        <AppBar classes={{root: classes.navBar}} position="static">
            <React.Fragment key="right">
                <Button onClick={() => setOpenDrawer(true)}><MenuIcon classes={{root: classes.hamburgerButton}}/></Button>
                <Drawer classes={{paper: classes.drawer}} anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
                    <NavLink onClick={()=>setOpenDrawer(false)} className={classes.drawerLink} to="/">Inicio</NavLink>
                    <NavLink onClick={()=>setOpenDrawer(false)}  className={classes.drawerLink} to="nuestra-historia">Nuestra Historia</NavLink>
                    {/* <NavLink className={classes.drawerLink} to="los-de-la-foto">Los de la foto</NavLink> */}
                    <NavLink onClick={()=>setOpenDrawer(false)}  className={classes.drawerLink} to="fotos">Fotos</NavLink>
                    <NavLink onClick={()=>setOpenDrawer(false)}  className={classes.drawerLink} to="registro">Registro</NavLink>
                    <NavLink onClick={()=>setOpenDrawer(false)}  className={classes.drawerLink} to="direcciones">Direcciones</NavLink>
                </Drawer>
            </React.Fragment>
            <Toolbar classes={{root: classes.toolbar}}>
                <NavLink to="/">Inicio</NavLink>
                <NavLink to="nuestra-historia">Nuestra Historia</NavLink>
                {/* <NavLink to="los-de-la-foto">Los de la foto</NavLink> */}
                <NavLink to="fotos">Fotos</NavLink>
                <NavLink to="registro">Registro</NavLink>
                <NavLink to="direcciones">Direcciones</NavLink>
            </Toolbar>
        </AppBar>
    );
};