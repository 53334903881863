import React from 'react';
import '@fontsource/roboto';
import '@fontsource/rochester';
import '@fontsource/montserrat';
import '@fontsource/raleway';
import '@fontsource/roboto-condensed';
import { Grid, makeStyles } from "@material-ui/core";
import { differenceInDays } from "date-fns";
import { Router } from "@reach/router";
import { Inicio } from "./Inicio";
import { NuestraHistoria } from "./NuestraHistoria";
import { Menu } from "./Menu";
import { Footer } from "./Footer";
import { Direcciones } from "./Direcciones";
import { Fotos } from "./Fotos";
import { Registro } from "./Registro";
import flowersDetail from "./images/flowers-detail.png"
import flowersDetail2 from "./images/flowers-detail-2.png"
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export const GREEN = "#083A39";
export const GOLD = "#DFBB8B";

const styles = makeStyles(theme => ({
  mainTitle: {
    marginTop: 200,
    fontFamily: "Goodest, sans-serif",
    fontSize: 62,
    color: GOLD,
    position: "relative",
    marginLeft: -80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: 100,
    }
  },
  victorTitle: {
    position: "relative",
    top: -65,
    left: 90,
  },
  andTitle: {
    marginRight: 0,
    fontSize: "0.7em",
    position: "relative",
    top: -15,
  },
  subTitle: {
    color: GOLD,
    fontSize: 20,
    textAlign: "center",
  },
  flowerDetail: {
    position: "absolute",
    left: 0,
    height: 300,
    top: 0,
    transform: "rotateY(180deg)",
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      maxHeight: "30vh",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  flowerDetail2: {
    position: "absolute",
    right: 0,
    height: 300,
    top: 0,
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      maxHeight: "30vh",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
    // transform: "rotateY(180deg)",
  },
  headerContainer: {
    justifyContent:"center",
    alignItems: "center",
    order: 1,
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      order: 2,
      marginBottom: 60,
    }
  },
  menuContainer: {
    width: "100%",
    order: 2,
    [theme.breakpoints.down("md")]: {
      order: 1,
    }
  },
  contentContainer:{
    order: 3,
    width: "100%",
    overflow: "hidden",
  },
  "@global": {
    "html": {
      overflowX: "hidden",
    }
  }
}));

function App() {
  const classes = styles();
  const daysUntil = differenceInDays(new Date(2021,9,9), new Date() );
  return (
    <Grid container={true} justifyContent="center" alignItems="center" direction="column">
      <div className={classes.headerContainer}>
        <img src={flowersDetail} className={classes.flowerDetail} alt="Flower"/>
        <img src={flowersDetail2} className={classes.flowerDetail2} alt="Flower"/>
        <Grid item={true} classes={{root: classes.mainTitle}}><span className={classes.victorTitle}>Victor</span><span className={classes.andTitle}>&</span>Landy</Grid>
        <Grid item={true} classes={{root: classes.subTitle}}>9 de octubre 2021, Ciudad de Guatemala</Grid>
        <Grid item={true} classes={{root: classes.subTitle}}>Faltan {daysUntil > 0 ? daysUntil : 0} días!</Grid>
      </div>
      <div className={classes.menuContainer}>
        <Menu/>
      </div>
      <div className={classes.contentContainer}>
        <Router primary={false}>
          <ScrollToTop path="/">
            <Inicio path="/" />
            <NuestraHistoria path="nuestra-historia" />
            <Fotos path="fotos" />
            <Direcciones path="direcciones" />
            <Registro path="registro" />
          </ScrollToTop>
        </Router>
      </div>

      <Footer/>
    </Grid>
  );
}


export const ScrollToTop = (props: any) => {
  const { children, location } = props;
  React.useEffect(() => window.scrollTo(0, 0), [location.pathname])
  return children
}

export default App;
