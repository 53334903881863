import React, { useRef, useEffect } from "react";
import {
    Grid,
    makeStyles,
    Typography,
    darken
} from "@material-ui/core";
import { GOLD, GREEN } from "./App";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const styles = makeStyles(theme => ({
    title: {
        fontFamily: "Goodest, sans-serif",
        color: GOLD,
        fontSize: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 50,
        marginTop: 50,
    },
    sectionTitle: {
        fontFamily: "Raleway",
        maxWidth: 1500,
        width: "100%",
        marginBottom: 40,
    },
    sectionTitleH1: {
        fontFamily: "Raleway, sans-serif",
        color: GOLD,
        fontSize: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    sectionTitleH2: {
        fontFamily: "Raleway, sans-serif",
        color: GOLD,
        fontSize: 26,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 5,
        whiteSpace: "nowrap",
    },
    sectionBody: {
        color: GOLD,
        maxWidth: 1200,
        margin: "auto",
        marginBottom: 40,
        padding: "0 20px",
        [theme.breakpoints.down("md")]: {
            padding: "0 40px"
        }
    },
    wazeLink: {
        // backgroundColor: GOLD,
        backgroundImage: `linear-gradient(to left, ${GOLD} 50%, ${darken(GOLD,0.2)} 50%)`,
        backgroundSize: "200% 100%",
        color: GREEN,
        padding: "10px 40px",
        borderRadius: 25,
        fontSize: 20,
        marginTop: 20,
        fontWeight: "bold",
        textDecoration: "none",
        fontFamily: 'Roboto',
        backgroundPosition: "100% 0",
        transition: "all 0.5s",
        "&:hover":{
            backgroundPosition: "0 0",
        }
    },
    directionsIframe: {
        maxWidth: "100%",
    }
}));

export const Direcciones = (props: any) => {
    const classes = styles();

    const revealRefs:any = useRef([]);
    revealRefs.current = [];
    useEffect(() => {
        revealRefs.current.forEach((el:any, index:any) => {
          gsap.fromTo(el, {
            opacity: 0,
            x: -200,
          }, {
            duration: 0.5,
            x: 0,
            opacity: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
            }
          });

        });

      }, []);

    const addToRefs = (el: any) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    return <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
            <Typography variant="h1" color="textPrimary" classes={{root: classes.title}}>Direcciones</Typography>
        </Grid>
        <Grid item>
            <Grid container spacing={1} justifyContent="center" classes={{root: classes.sectionTitle}} direction="column">
                <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.sectionTitleH1}}>A&#160;la&#160;Iglesia</Typography>
                <Typography ref={addToRefs} variant="h2" color="textPrimary" classes={{root: classes.sectionTitleH2}}>Ruta 6 8-52 zona 4</Typography>
            </Grid>
            <Grid container spacing={1} justifyContent="center" >
                <Typography ref={addToRefs} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    La iglesia se encuentra sobre la ruta 6, zona 4. Puedes llegar de distintas formas: si vienes sobre la 7ma avenida, puedes girar a la derecha para incorporarte a la ruta 6 y la iglesia quedará a tu mano derecha. Si vienes de la ruta 6, debes seguir recto y luego de cruzar la 7ma avenida, encontrarás la iglesia a tu mano derecha. Si vienes de zona 10 por la reforma, o por la 10a avenida de la zona 5, puedes tomar el redondel al final de la reforma y salir a la ruta 6, pero la iglesia quedaría en el otro carril. Es más fácil tomar el redondel hacia el auxiliar de la Reforma en dirección al obelisco y cruzar a la derecha en la 1ra calle: de esta forma te incorporarás a la 7ma avenida y puedes tomar la ruta 6 en el carril correcto.
                </Typography>
            </Grid>

        </Grid>
        <iframe title="Direcciones iglesia" ref={addToRefs} className={classes.directionsIframe} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.705195424974!2d-90.51726488476517!3d14.615862489792768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a2324010c1e3%3A0x2cb8fbcde12460e9!2sIglesia%20Yurrita!5e0!3m2!1sen!2sgt!4v1628908668998!5m2!1sen!2sgt" width="600" height="450" style={{border:0}} allowFullScreen loading="lazy"></iframe>
        <a ref={addToRefs} className={classes.wazeLink} href="https://waze.com/ul/h9fxehkc06">Abrir en Waze</a>
        <Grid item style={{marginTop: 60}}>
            <Grid container spacing={1} justifyContent="center" classes={{root: classes.sectionTitle}} direction="column">
                <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.sectionTitleH1}}>Al&#160;Hotel</Typography>
                <Typography ref={addToRefs} variant="h2" color="textPrimary" classes={{root: classes.sectionTitleH2}}>14 calle 2-51 zona 10</Typography>
            </Grid>
            <Grid container spacing={1} justifyContent="center" >
                <Typography ref={addToRefs} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    Saliendo de la iglesia, llegar al hotel es sencillo: sigue la ruta 6 (la calle donde está la iglesia) y llegarás a un redondel donde puedes tomar tu primera derecha y estará en la avenida Reforma. Sigue la avenida Reforma hasta la 14 calle de la zona 10, y luego gira a la derecha (hay un semáforo). Luego de atravesar la Reforma, sigue 2 cuadras sobre la 14 calle: gira a la izquierda en la 2a avenida y el parqueo del hotel se encontrará a tu mano derecha. Bienvenido a la fiesta!
                </Typography>
            </Grid>
        </Grid>
        <iframe title="Direcciones hotel" ref={addToRefs} className={classes.directionsIframe} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15444.068011251287!2d-90.514481!3d14.598107!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2428530068718505!2sInterContinental%20Real%20Guatemala%2C%20an%20IHG%20Hotel!5e0!3m2!1sen!2sgt!4v1628908464740!5m2!1sen!2sgt" width="600" height="450" style={{border:0}} allowFullScreen loading="lazy"></iframe>
        <a ref={addToRefs} className={classes.wazeLink} href="https://waze.com/ul/h9fxeh391m">Abrir en Waze</a>
    </Grid>
}