import React, { useRef, useEffect } from "react";
import {
    Grid,
    Dialog,
    DialogContent,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { GOLD } from "./App";
import img7colores from "./images/7colores.jpg";
import img30 from "./images/30.jpeg";
import imgArjona from "./images/Arjona.jpeg";
import imgBoda from "./images/Boda.jpeg";
import imgCayala from "./images/Cayala.jpeg";
import imgChichicastenango from "./images/Chichicastenango.jpeg";
import imgCostarica from "./images/CostaRica.jpeg";
import imgCostarica2 from "./images/CostaRica2.jpeg";
import imgFontabella from "./images/Fontabella.jpeg";
import imgGraduacion from "./images/Graduacion.jpeg";
import imgGraduacionMafer from "./images/graduacionMafer.jpg";
import imgLima from "./images/Lima.jpeg";
import imgNiebla from "./images/Niebla.jpeg";
import imgPana from "./images/Pana.jpeg";
import imgPijama from "./images/Pijama.jpeg";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const styles = makeStyles(theme => ({
    mainContainer: {
        maxWidth: 1000,
        padding: "0 20px",
        margin: "auto"
    },
    title: {
        fontFamily: "Goodest, sans-serif",
        color: GOLD,
        fontSize: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 50,
        marginTop: 50,
    },
    thumb: {
        objectFit: "cover",
        width: 300,
        height: 300,
        margin: 10,
        border: `1px solid ${GOLD}`,
    },
    modal: {
        background: "transparent",
        boxShadow: "none",
    },
    imgContainer: {
        padding: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    imgFull: {
        width: "auto",
        height: "auto",
        maxWidth: "calc(100vw - 40px)",
        maxHeight: "calc(100vh - 40px)",
        objectFit: "contain",
    },
    modalContent: {
        padding: 20,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}));

const photos = [
    {
        url: img7colores
    },
    {
        url: img30
    },
    {
        url: imgArjona
    },
    {
        url: imgBoda
    },
    {
        url: imgCayala
    },
    {
        url: imgChichicastenango
    },
    {
        url: imgCostarica
    },
    {
        url: imgCostarica2
    },
    {
        url: imgFontabella
    },
    {
        url: imgGraduacion
    },
    {
        url: imgLima
    },
    {
        url: imgNiebla
    },
    {
        url: imgPana
    },
    {
        url: imgPijama
    },
    {
        url: imgGraduacionMafer
    }
]


export const Fotos = (props:any) => {
    const [open, setOpen] = React.useState(false);
    const [photoURL, setPhotoURL] = React.useState(photos[0].url);
    const classes = styles();
    const revealRefs:any = useRef([]);
    revealRefs.current = [];

    useEffect(() => {
        revealRefs.current.forEach((el:any, index:any) => {
          gsap.fromTo(el, {
            opacity: 0,
            y: 200,
          }, {
            duration: 0.5,
            y: 0,
            opacity: 1,
            ease: 'none',
            delay: ((index % 3) - 1) * 0.1,
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
            }
          });

        });

      }, []);

    const addToRefs = (el: any) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    return <Grid container spacing={1} alignContent="center" justifyContent="space-around" classes={{root: classes.mainContainer}}>
        <Grid item xs={12}>
            <Typography variant="h1" color="textPrimary" classes={{root: classes.title}}>Fotos</Typography>
        </Grid>
        {
            photos.map((photo, index) => <>
                {/* <Grid item xs={12} md={4} classes={{root: classes.imgContainer}}> */}
                    <img src={photo.url} className={classes.thumb} onClick={() => {setPhotoURL(photo.url); setOpen(true);}} key={index} ref={addToRefs} alt=""/>
                {/* </Grid> */}
                </>)
        }
        <Dialog
            open={open}
            onClose={()=>setOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            classes={{paper: classes.modal}}
            fullScreen={true}
            >
                <DialogContent classes={{root: classes.modalContent}} onClick={()=>setOpen(false)}>
                    <img src={photoURL} alt="" className={classes.imgFull}/>
                </DialogContent>
        </Dialog>
    </Grid>
}