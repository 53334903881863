import React, {useRef, useEffect} from "react";
import {
    Grid,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { GOLD } from "./App";
import imgEscape from "./images/Escape.jpeg";
import imgHalloween from "./images/Halloween.jpeg";
import imgNovios from "./images/Novios.jpeg";
import imgEngagement from "./images/Engagement.jpeg";
import imgLlaves from "./images/Llaves.jpeg";
import imgPeru from "./images/Peru.jpeg";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const styles = makeStyles(theme => ({
    title: {
        fontFamily: "Goodest, sans-serif",
        color: GOLD,
        fontSize: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 50,
        marginTop: 50,
        [theme.breakpoints.down("sm")]: {
            fontSize: 50,
        }
    },
    sectionBody: {
        color: GOLD,
        maxWidth: 1200,
        margin: "auto",
        padding: "0 20px"
    },
    container: {
        maxWidth: 1240,
        width: "90%",
    },
    historiaPhoto: {
        maxWidth: "100%",
    },
    entry: {
        display: "flex",
        padding: 40,
        justifyContent: "center",
        alignItems: "center",
    },
    imgEntry: {
        [theme.breakpoints.down('sm')]: {
            order: 1,
        }
    },
    textEntry: {
        [theme.breakpoints.down('sm')]: {
            order: 2,
        }
    }
}));

export const NuestraHistoria = (props: any) => {
    const classes = styles();

    const revealRefs:any = useRef([]);
    revealRefs.current = [];
    useEffect(() => {
        revealRefs.current.forEach((el:any, index:any) => {
          gsap.fromTo(el, {
            opacity: 0,
            x: -200,
          }, {
            duration: 0.75,
            x: 0,
            opacity: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
            }
          });

        });

      }, []);

    const addToRefs = (el: any) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    const revealRefs2:any = useRef([]);
    revealRefs2.current = [];
    useEffect(() => {
        revealRefs2.current.forEach((el:any, index:any) => {
          gsap.fromTo(el, {
            opacity: 0,
            x: 200,
          }, {
            duration: 0.75,
            x: 0,
            opacity: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
            }
          });

        });

      }, []);

    const addToRefs2 = (el: any) => {
        if (el && !revealRefs2.current.includes(el)) {
            revealRefs2.current.push(el);
        }
    };


    return <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
            <Typography variant="h1" color="textPrimary" classes={{root: classes.title}}>Nuestra Historia</Typography>
        </Grid>
        <Grid container classes={{root: classes.container}}>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.imgEntry}`}}>
                <img ref={addToRefs} src={imgEscape} className={classes.historiaPhoto} alt="Primera cita"/>
            </Grid>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.textEntry}`}}>
                <Typography ref={addToRefs2} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                Nos conocimos gracias a amigos mutuos, Vivi, amiga de Víctor, y Brenda, prima de Landy. No por coincidencia, Vivi y su esposo Carlos serán padrinos de la boda. El 23 de agosto de 2018 hablamos por primera vez, y el sábado 25 tuvimos nuestra primera cita. Fue en un escape room, un cuarto con acertijos que tienes que ir resolviendo para salir, ya que a Landy le gustan. Como parte del juego nos esposaron juntos, y el primer acertijo era encontrar la llave de las esposas. Landy niega que esto fue a propósito, pero Víctor no lo cree. Desde un primer momento nos llevamos super bien, al punto que una semana después, Víctor ya estaba invitado a la graduación de Landy como abogada, donde conoció a toda su familia.
                </Typography>
            </Grid>
        </Grid>
        <Grid container classes={{root: classes.container}}>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.textEntry}`}}>
                <Typography ref={addToRefs} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    Parte de lo que nos enamoró fue la forma en la que siempre compartimos las locuras el uno del otro. Para octubre del 2018 ya habíamos salido un par de veces, y junto a unos amigos decidimos disfrazarnos para Halloween. Nuestros disfraces fueron de Beetlejuice y Lidia, y esa noche compartimos nuestro primer beso. Desde ese entonces hemos compartido muchas locuras, como lanzarnos de Parapente, salir juntos en pijama o disfrazarnos varias veces más.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.imgEntry}`}}>
                <img ref={addToRefs2} src={imgHalloween} className={classes.historiaPhoto} alt="Halloween"/>
            </Grid>
        </Grid>
        <Grid container classes={{root: classes.container}}>
        <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.imgEntry}`}}>
                <img ref={addToRefs} src={imgNovios} className={classes.historiaPhoto} alt="Novios"/>
            </Grid>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.textEntry}`}}>
                <Typography ref={addToRefs2} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    El 17 de noviembre de 2018 decidimos hacernos novios. Víctor trató de darle una sorpresa a Landy, así que la llevó al restaurante Welten en la Antigua. Como era sorpresa, Landy no sabía que iban a la Antigua y llevó tacones. Hasta la fecha, Landy no ha perdonado a Víctor por esto. La relación siguió desarrollandose y ambos nos sentimos muy cómodos con la familia del otro: para fin de año Landy ya pasó Nochebuena con la familia de Víctor y Víctor Año Nuevo con la familia de Landy, tradición que continúa hasta la fecha.
                </Typography>
            </Grid>
        </Grid>
        <Grid container classes={{root: classes.container}}>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.textEntry}`}}>
                <Typography ref={addToRefs} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    En el 2019 seguimos conociéndonos y estábamos cada vez más convencidos que esta era la persona con quien queremos pasar el resto de nuestra vida. Sin saber que un par de meses después las fronteras estarían cerradas y nuestras vidas cambiarían radicalmente, en agosto de 2019 hicimos nuestro primer viaje juntos: un tour junto a un grupo de amigos a Perú, en donde conocimos muchos lugares hermosos hechos aún más hermosos por la compañía del otro.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.imgEntry}`}}>
                <img ref={addToRefs2} src={imgPeru} className={classes.historiaPhoto} alt="Machu Pichu"/>
            </Grid>
        </Grid>
        <Grid container classes={{root: classes.container}}>
        <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.imgEntry}`}}>
                <img ref={addToRefs} src={imgEngagement} className={classes.historiaPhoto} alt="Engagement"/>
            </Grid>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.textEntry}`}}>
                <Typography ref={addToRefs2} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    El 2020 fue un año difícil para todos, y nosotros no fuimos la excepción. Hubieron cambios en nuestras vidas y tuvimos que hacer muchos ajustes para combatir la situación que todos vivimos, pero nuestro amor se mantuvo constante y el 17 de noviembre del 2020, tomamos la decisión de unir nuestras vidas. Debido a que las medidas de prevención significaban que muchos lugares se encontraban cerrados, Víctor se las ingenió para encontrar una forma de pedir la mano de Landy sin salir de casa. La solución: un escape room hecho en casa, con todos los acertijos basados en nuestra relación y siendo la última pregunta la más importante: "¿Quieres casarte conmigo?". Así, nuestro noviazgo terminaba de la misma forma como empezó y ahora nos preparabamos juntos para una nueva etapa en nuestras vidas.
                </Typography>
            </Grid>
        </Grid>
        <Grid container classes={{root: classes.container}}>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.textEntry}`}}>
                <Typography ref={addToRefs} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    El 2021 ha sido una mezcla de emociones: anticipación por lo que se viene adelante, alegrías al prepararnos para nuestra vida juntos y no faltan las tristezas, como al despedir a la abuelita de Víctor quien falleció este año. En la foto vemos una de nuestras más grandes emociones del año: el momento en el que se aprobó nuestro préstamo y nos dieron las llaves de nuestra nueva casa. Así, poco a poco nos hemos ido preparando para el camino que el 9 de octubre empezaremos a recorrer.
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} classes={{root: `${classes.entry} ${classes.imgEntry}`}}>
                <img ref={addToRefs2} src={imgLlaves} className={classes.historiaPhoto} alt="Llaves de la nueva casa"/>
            </Grid>
        </Grid>
    </Grid>;
}