import React, { useEffect, useLayoutEffect, useRef } from "react";
import {
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";
import victorYLandy from "./images/victorylandy2.png";
import { GOLD } from "./App";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const styles = makeStyles(theme => ({
    heroContainer: {
        "@media (max-width: 1500px)": {
            paddingBottom: "50%",
        }
    },
    heroPic: {
        marginBottom: 80,
        maxWidth: "100%",
        minHeight: 700,
        "@media (max-width: 1500px)": {
            position: "absolute",
            minHeight: 0,
        }
    },
    date: {
        fontFamily: "Goodest, sans-serif",
        color: GOLD,
        fontSize: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    dateSmall: {
        fontFamily: "Goodest, sans-serif",
        color: GOLD,
        fontSize: 30,
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
            marginTop: 20
        }
    },
    dateCol: {
        justifyContent: "center",
        width: "50%",
        textAlign: "center",
        minHeight: 100,
        maxWidth: 500,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        padding: "30px 0 !important",

        "&:first-child": {
            borderRight: `1px solid ${GOLD}`,

            [theme.breakpoints.down("md")]: {
                borderRight: 0,
                borderBottom: `1px solid ${GOLD}`,
            }
        }
    },
    sectionTitle: {
        fontFamily: "Raleway",
        maxWidth: 1500,
        width: "100%",
        marginBottom: 40,
    },
    sectionTitleH1: {
        fontFamily: "Raleway, sans-serif",
        color: GOLD,
        fontSize: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    sectionContent: {
        maxWidth: 1500,
        width: "100%",
        marginBottom: 80,
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        }
    },
}));

export const Inicio = (props: any) => {
    const classes = styles();
    const heroRef:any = useRef();
    useLayoutEffect(() => {
        gsap.from(heroRef.current, {
            opacity: 0,
            ease: "power2.out",
            duration: 3,
            scrollTrigger: heroRef.current,
        })
    });
    const revealRefs:any = useRef([]);
    revealRefs.current = [];
    useLayoutEffect(() => {
        revealRefs.current.forEach((el:any, index:any) => {
          gsap.fromTo(el, {
            opacity: 0,
            x: -200,
          }, {
            duration: 0.75,
            x: 0,
            opacity: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
            }
          });

        });

      }, []);

    const addToRefs = (el: any) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    const revealRefs2:any = useRef([]);
    revealRefs2.current = [];
    useEffect(() => {
        revealRefs2.current.forEach((el:any, index:any) => {
          gsap.fromTo(el, {
            opacity: 0,
            x: 200,
          }, {
            duration: 0.75,
            x: 0,
            opacity: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
            }
          });

        });

      }, []);

    const addToRefs2 = (el: any) => {
        if (el && !revealRefs2.current.includes(el)) {
            revealRefs2.current.push(el);
        }
    };

    return <Grid container spacing={1} alignContent="center" justifyContent="center">
        <Grid container spacing={1} justifyContent="center" className={classes.heroContainer}>
            <img ref={heroRef} src={victorYLandy} className={classes.heroPic} alt="Landy y Victor abrazandose"/>
        </Grid>
        <Grid container spacing={1} justifyContent="center" classes={{root: classes.sectionTitle}}>
            <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.sectionTitleH1}} className="animate">La boda</Typography>
        </Grid>
        <Grid container spacing={1} justifyContent="center" classes={{root: classes.sectionContent}}>
            <Grid item classes={{root: classes.dateCol}}>
                <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.date}}>9 Octubre, 2021</Typography>
                <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.dateSmall}}>4:00 pm</Typography>
            </Grid>
            <Grid item classes={{root: classes.dateCol}}>
                <Typography ref={addToRefs2} variant="h1" color="textPrimary" classes={{root: classes.date}}>Iglesia Yurrita</Typography>
                <Typography ref={addToRefs2} variant="h1" color="textPrimary" classes={{root: classes.dateSmall}}>Ruta 6 8-52 zona 4</Typography>
            </Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="center" classes={{root: classes.sectionTitle}}>
            <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.sectionTitleH1}}>El purrún</Typography>
        </Grid>
        <Grid container spacing={1} justifyContent="center" classes={{root: classes.sectionContent}}>
            <Grid item classes={{root: classes.dateCol}}>
                <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.date}}>9 Octubre, 2021</Typography>
                <Typography ref={addToRefs} variant="h1" color="textPrimary" classes={{root: classes.dateSmall}}>6:00 pm</Typography>
            </Grid>
            <Grid item classes={{root: classes.dateCol}}>
                <Typography ref={addToRefs2} variant="h1" color="textPrimary" classes={{root: classes.date}}>Hotel Intercontinental  </Typography>
                <Typography ref={addToRefs2} variant="h1" color="textPrimary" classes={{root: classes.dateSmall}}>14 calle 2-51 zona 10</Typography>
            </Grid>
        </Grid>
    </Grid>
}