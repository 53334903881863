import React from "react";
import {
    Grid, Typography, makeStyles, Toolbar
} from "@material-ui/core";
import { GOLD } from "./App";
import flowerWide from "./images/flowers-wide2.png";
import { NavLink } from "./Menu";

const styles = makeStyles(theme => ({
    footer: {
        paddingTop: 80,
        order: 4,
    },
    initials: {
        fontFamily: "Goodest, sans-serif",
        fontSize: 62,
        color: GOLD,
        position: "relative",
    },
    date: {
        color: GOLD,
        borderTop: `1px solid ${GOLD}`,
        padding: "16px 40px",
    },
    flower: {
        maxWidth: "100%",
    },
    toolbar: {
        justifyContent: "space-between",
        width: "100%",
        maxWidth: 1200,
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            display: "none"
        }
    },
    navLink: {
        marginTop: 20,
    }
  }));

export const Footer = (props:any) => {
    const classes = styles();
    return <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center" classes={{root: classes.footer}}>
        <Typography variant="h1" color="initial" classes={{root: classes.initials}}>V<span style={{fontSize: 40}}>&</span>L</Typography>
        <Typography variant="h4" color="initial" classes={{root: classes.date}}>9.OCT.2021</Typography>
        <Toolbar classes={{root: classes.toolbar}}>
            <NavLink className={classes.navLink} to="/">Inicio</NavLink>
            <NavLink className={classes.navLink} to="nuestra-historia">Nuestra Historia</NavLink>
            {/* <NavLink to="los-de-la-foto">Los de la foto</NavLink> */}
            <NavLink className={classes.navLink} to="fotos">Fotos</NavLink>
            <NavLink className={classes.navLink} to="registro">Registro</NavLink>
            <NavLink className={classes.navLink} to="direcciones">Direcciones</NavLink>
        </Toolbar>
        <img src={flowerWide} className={classes.flower} alt="Flower detail" />
    </Grid>
}