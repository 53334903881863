import React, {useEffect, useRef} from "react";
import {
    Grid,
    makeStyles,
    Typography,
    darken
} from "@material-ui/core";
import { GOLD, GREEN } from "./App";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


const styles = makeStyles(theme => ({
    title: {
        fontFamily: "Goodest, sans-serif",
        color: GOLD,
        fontSize: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 50,
        marginTop: 50,
    },
    sectionTitle: {
        fontFamily: "Raleway",
        maxWidth: 1500,
        width: "100%",
        marginBottom: 40,
    },
    sectionTitleH1: {
        fontFamily: "Raleway, sans-serif",
        color: GOLD,
        fontSize: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    sectionTitleH2: {
        fontFamily: "Raleway, sans-serif",
        color: GOLD,
        fontSize: 26,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 5,
        whiteSpace: "nowrap",
    },
    sectionBody: {
        color: GOLD,
        maxWidth: 1200,
        margin: "auto",
        marginBottom: 40,
        padding: "0 20px",
        [theme.breakpoints.down("md")]: {
            padding: "0 40px"
        }
    },
    wazeLink: {
        // backgroundColor: GOLD,
        backgroundImage: `linear-gradient(to left, ${GOLD} 50%, ${darken(GOLD,0.2)} 50%)`,
        backgroundSize: "200% 100%",
        color: GREEN,
        padding: "10px 40px",
        borderRadius: 25,
        fontSize: 20,
        marginTop: 20,
        fontWeight: "bold",
        textDecoration: "none",
        fontFamily: 'Roboto',
        backgroundPosition: "100% 0",
        transition: "all 0.5s",
        "&:hover":{
            backgroundPosition: "0 0",
        }
    },
    directionsIframe: {
        maxWidth: "100%",
    }
}));

export const Registro = (props: any) => {
    const classes = styles();

    const revealRefs:any = useRef([]);
    revealRefs.current = [];
    useEffect(() => {
        revealRefs.current.forEach((el:any, index:any) => {
          gsap.fromTo(el, {
            opacity: 0,
            x: -200,
          }, {
            duration: 0.5,
            x: 0,
            opacity: 1,
            ease: 'none',
            scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
            }
          });

        });

      }, []);

    const addToRefs = (el: any) => {
        if (el && !revealRefs.current.includes(el)) {
            revealRefs.current.push(el);
        }
    };

    return <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
        <Grid item xs={12}>
            <Typography variant="h1" color="textPrimary" classes={{root: classes.title}}>Registro</Typography>
        </Grid>
        <Grid item>
            <Grid container spacing={1} justifyContent="center" >
                <Typography ref={addToRefs} variant="body1" color="textPrimary" classes={{root: classes.sectionBody}}>
                    Celebrar con ustedes en este día tan especial es el único regalo que necesitamos. Sin embargo, para los amigos y familia que han expresado interés, hemos creado un registro de regalos en Cemaco al cuál puedes acceder en cualquier tienda o haciendo click en el botón de abajo:
                </Typography>
            </Grid>
        </Grid>
        <a ref={addToRefs} target="_blank" rel="noreferrer" className={classes.wazeLink} href="https://www.cemaco.com/list/2021100030A">Ir a Cemaco</a>
    </Grid>
}